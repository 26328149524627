export function checkForSameOrigin(targetURL, sourceURL, allowedPathnameRegex, returnURLInstanceOnSuccess = false) {
  //Try to convert the URL of the target and of the source and compare the origin for security when adding authentication to the requests!
  try {
    const targetURLInstance = new URL(targetURL);
    const sourceURLInstance = new URL(sourceURL);

    //Only test pathname regex, if it is set
    if (allowedPathnameRegex != null) {
      if (targetURLInstance.pathname == null || !(allowedPathnameRegex.test(targetURLInstance.pathname))) {
        return (returnURLInstanceOnSuccess) ? undefined : false;
      }
    }

    //Check if they both have the same origin, only then accept this request
    if (targetURLInstance.origin === sourceURLInstance.origin) {
      return (returnURLInstanceOnSuccess) ? targetURLInstance : true;
    }
    //Alternatively check all parts separately that the origin consists of
    if (
      targetURLInstance.protocol === sourceURLInstance.protocol &&
      targetURLInstance.hostname === sourceURLInstance.hostname &&
      targetURLInstance.port === sourceURLInstance.port
    ) {
      return (returnURLInstanceOnSuccess) ? targetURLInstance : true;
    }
  } catch {
    //If it can't be converted to a valid URL, treat it as different origin for security reasons
    return (returnURLInstanceOnSuccess) ? undefined : false;
  }

  return (returnURLInstanceOnSuccess) ? undefined : false;
}