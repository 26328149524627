<template id="authenticated-media">
  <img v-if="type === 'image'" :id="id" :class="cssClass" :style="style" :draggable="draggable" :src="computedURL" :crossorigin="crossoriginAttribute" :onerror="errorHandler" />
  <audio v-if="type === 'audio'" :id="id" :class="cssClass" :style="style" :draggable="draggable" :src="computedURL" :crossorigin="crossoriginAttribute" :onerror="errorHandler"></audio>
  <video v-if="type === 'video'" :id="id" :class="cssClass" :style="style" :draggable="draggable" :src="computedURL" :crossorigin="crossoriginAttribute" :controls="controls" :playsinline="playsinline" :poster="poster" :onerror="errorHandler">
    <source :src="computedURL" :type="mimeType">
  </video>
</template>

<script>
import { defineComponent, computed, onBeforeUnmount, watch, ref } from 'vue';

import { TRANSPARENT_PNG } from '@/utils/media';

import { useStore } from 'vuex';

export default defineComponent({
  name: 'AuthenticatedMedia',
  props: {
    'id': String,
    'cssClass': String,
    'style': String,
    'type': String,
    'mimeType': String,
    'draggable': {
      type: Boolean,
      default: false
    },
    'controls': {
      type: Boolean,
      default: false
    },
    'poster': [Object, String],
    'playsinline': {
      type: Boolean,
      default: false
    },
    'mediaUrl': String
  },
  setup(props) {
    const store = useStore();

    const objectURL = ref(undefined);

    //Save fallback separately to not accidentally revoke an outside object URL
    const fallbackURL = ref(undefined);

    const computedURL = computed(() => {
      if (objectURL.value != null) return objectURL.value;
      if (fallbackURL.value != null) return fallbackURL.value;
      return TRANSPARENT_PNG;
    })

    const loadedThroughRelay = computed(() => store.getters['auth/isAuthenticationRelayActive']);

    const crossoriginAttribute = computed(() => {
      //If loaded over the network, set to anonymous
      if (loadedThroughRelay.value) {
        return 'anonymous';
      }
      return undefined;
    });

    watch([() => props.mediaUrl, loadedThroughRelay], ([url, throughRelay]) => {
      const oldURL = objectURL.value;
      
      //Anonymous async function to retrieve a promise for laoding to either complete or fail or never be triggered
      let loadPromise = (async () => {
        if (!(throughRelay))  { //Load it manually - no relay available
          await store.dispatch('fetchMediaWithAuthentication', url).then((mediaBlob) => {
            if (mediaBlob != null && mediaBlob instanceof Blob) {
              objectURL.value = URL.createObjectURL(mediaBlob);
            }
          }).catch((error) => {
            console.error('Error loading media with authentication', error);
            objectURL.value = undefined;
          });
        }
      })();
      //Dispatch revoke to not trigger image error by revoking while it is still set as the src
      setTimeout(() => {
        if (oldURL != null) URL.revokeObjectURL(oldURL);
      }, 1000);

      //If either the relay is responsible or local files are accessed or any other edge case, set the URL and let the browser handle the loading
      //Set after loading to not immediately show a faulty media
      loadPromise.finally(() => fallbackURL.value = url);
    }, { immediate: true });

    onBeforeUnmount(() => {
      const oldURL = objectURL.value;
      objectURL.value = undefined;
      //Dispatch revoke to not trigger image error by revoking while it is still set as the src
      setTimeout(() => {
        if (oldURL != null) URL.revokeObjectURL(oldURL);
      }, 1000); 
    });

    const errorHandler = function(errorEvent) {
      let src;
      if (errorEvent != null && errorEvent.target != null) {
        src = errorEvent.target.src; //TODO Same for video?
      }
      console.error(`Error loading authenticated media of type ${props.type}: `,  src);
    }

    return { computedURL, crossoriginAttribute, errorHandler }
  },
})
</script>

<style scoped>
</style>