import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

import _ from 'lodash';

import skeleton from './modules/skeleton';
import auth from './modules/auth';
import reports from './modules/reports';
import horses from './modules/horses';
import generalFeedback from './modules/general-feedback';
import customization from './modules/customization';
import encyclopedia from './modules/encyclopedia';

import { api, createStorage, apiBaseURL } from '@/utils/api';
import { AUTH_ERRORS } from '@/utils/error';
import { sendMessageToWaitingServiceWorkers } from '@/utils/service_workers';

import { Drivers } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

import { cloudUpload, cloudDone, cloudOffline, alert } from 'ionicons/icons';
import { checkForSameOrigin } from '@/authentication-caching-utils';

const UPDATE_INTERVAL_MINS = 2;

const CONSOLE_INTERCEPT_METHODS = [
  'log',
  'info',
  'warn',
  'error'
]

const BLOCKED_SEARCH_STRING = 'blocked';

const MAX_LOG_COUNT = 100;

const logStorage = createStorage({
    name: '__logs',
    driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
  },
  [CordovaSQLiteDriver]
); //Logs are always persisted to resolve errors

const MODULE_VERSION = 5; //UPDATE VERSION WITH EVERY BREAKING CHANGE OR ATTRIBUTE REMOVAL

const getDefaultState = () => {
  return {
    version: undefined,
    darkModePreference: undefined, //Undefined means to use the automatic device value
    darkModeAutomaticValue: false,
    onlineStatus: true,
    notifications: []
  }
};

const NON_PERSISTED_PATHS = [];

const modules = {
  skeleton,
  auth,
  reports,
  horses,
  generalFeedback,
  customization,
  encyclopedia
};

const store = createStore({
  plugins: [createPersistedState({ //TODO Adapt to use Ionic Native Storage
    reducer (newPersistedState) { //Set empty state in store when logged out to not store any sensible information
      if(api.getToken().value == null) {      
        return {};
      }
      let newSanitizedState = _.omit(newPersistedState, NON_PERSISTED_PATHS);
      _.update(newSanitizedState, 'notifications', (notificationArray) => {
        if (notificationArray == null) return [];
        //Remove all notifications that should not be persisted (e.g. update notification that gets set after every refresh)
        return _.filter(notificationArray, (notification) => (notification != null && !(notification.no_persist)));
      });
      return newSanitizedState;
    }
  })], //Saves the state by default in localStorage for the current session
  modules,
  state: getDefaultState,
  getters: {
    isDarkModePreferred (state) {
      return state.darkModePreference;
    },
    isDarkModeActive (state) {
      if (state.darkModePreference !== undefined) { //If a preference for dark mode has been set, this preferred value is used
        return state.darkModePreference;
      } else { //Else the automatic value is used
        return state.darkModeAutomaticValue;
      }
    },
    isOnline (state) {
      return state.onlineStatus;
    },
    getLogs () {
      return async () => {
        let logArray = [];
        
        return logStorage.forEach((value, key) => {
          logArray.push(
            {
              timestamp: key,
              ...value
            }
          );
        }).then(() => logArray);
      }
    },
    getStorageInfos (state, getters, rootState, rootGetters) {
      return api.getStorageInfos().then(async (storageInfos) => {
        //Add storage info for logStorage
        let keys = await logStorage.keys().then((keys) => (keys != null) ? keys.sort() : []).catch(() => []);
        let logStorageInfo = {
          name: logStorage.config.name,
          driver: logStorage.driver,
          keys 
        };

        let reportsStorageInfos = await rootGetters['reports/getStorageInfos'];

        return _.concat([logStorageInfo], storageInfos, reportsStorageInfos);
      });
    },
    getUploadStatus(state, getters, rootState, rootGetters) {
      let user = rootGetters['auth/getUser'];
      return _.get(api.getUploadIndex().value, [_.get(user, 'id'), 'all']);
    },
    getUploadStatusDesign (){
      return function(status) {
        if (status != null) {
          if (status.dataLost) {
            return {
              identifier: 'dataLost',
              color: '--ion-color-danger',
              shortColor: 'danger',
              icon: alert,
              canRetry: false,
              text: 'data_lost'
            }
          } else if (status.progress >= 100 || status.finishedID != null) {
            return {
              identifier: 'uploaded',
              color: '--ion-color-success-shade',
              shortColor: 'success',
              icon: cloudDone,
              canRetry: false,
              text: 'uploaded'
            }
          } else if (status.progress >= 0 && status.progress < 100) {
            return {
              identifier: 'uploading',
              color: '--ion-color-success-shade',
              shortColor: 'success',
              icon: cloudUpload,
              canRetry: false,
              text: 'uploading'
            }
          } else if (status.stoppedRetry) {
            return {
              identifier: 'stoppedRetry',
              color: '--ion-color-warning',
              shortColor: 'warning',
              icon: alert,
              canRetry: true,
              text: 'stopped_retry'
            }
          }
        }
        return {
          identifier: 'offline',
          color: '--ion-color-warning',
          shortColor: 'warning',
          icon: cloudOffline,
          canRetry: true,
          text: 'retry_offline'
        }
      }
    },
    getNotifications (state) {
      return state.notifications;
    }
  },
  mutations: {
    changeDarkModePreference (state, newValue) {
      state.darkModePreference = newValue;
    },
    changeDarkModeAutomaticValue (state, newValue) {
      state.darkModeAutomaticValue = newValue;
    },
    addNotification (state, newNotification) {
      //Each notification can have title, description, action, action_text and payload (for the action set) - Actions are predefined, e.g. "route to" or "reload page"
      if (newNotification == null || newNotification.title == null) return;
      //Add new notification without duplicates
      state.notifications = _.unionWith(state.notifications, [newNotification], _.isEqual);
    },
    removeNotification (state, notificationIndex) { 
      state.notifications.splice(notificationIndex, 1);
    },
    checkVersion (state, moduleKeys) { //Checks the version of this module and sets the state to default, if version is different
      //Used to reset state on breaking changes and to remove attributes that are no longer needed
      if (state.version !== MODULE_VERSION) {
        let defaultState = getDefaultState();
        Object.assign(state, defaultState); //Use setters to not impact any reactive functionality
        //Search for unused attributes and set them undefined, to remove them from the persisted state
        for (let key of Object.keys(state)){
          if (!(key in defaultState || moduleKeys.includes(key))){ //Exclude module keys, because they are handled separately
            state[key] = undefined;
          }
        }
        state.version = MODULE_VERSION;
      }
    },
    changeOnlineStatus (state, newStatus) {
      state.onlineStatus = newStatus;
    },
    clearPersonalData () {
      api.clearRouteMetadata();
    }
  },
  actions: {
    setDarkModeStatus (context) { //Always called after a change to set it in html
      if (context.state.darkModePreference !== undefined) { //If a preference for dark mode has been set, use this preferred value
        document.body.classList.toggle('dark', context.state.darkModePreference);
      } else { //Else set it from the automatic value
        document.body.classList.toggle('dark', context.state.darkModeAutomaticValue);
      }
    },
    setDarkModePreference (context, newValue) {
      context.commit('changeDarkModePreference', newValue);
      context.dispatch('setDarkModeStatus');
    },
    setDarkModeAutomaticValue (context, newValue) { //Call at least once on start, to set the status
      context.commit('changeDarkModeAutomaticValue', newValue);
      context.dispatch('setDarkModeStatus');
    },

    setOnline (context) {
      context.commit('changeOnlineStatus', true);
    },
    setOffline (context) {
      context.commit('changeOnlineStatus', false);
    },

    clearAllPersonalData (context) {
      //Check every version of every module
      const moduleKeys = Object.keys(modules);
      for (let module of moduleKeys){
        try {
          context.commit(`${module}/clearPersonalData`, { root: true });
        } catch (error) {
          console.error(`Could not clear personal data in module ${module}`, error);
        }
      }
      try {
        context.commit('clearPersonalData', { root: true });
      } catch (error) {
        console.error('Could not clear personal data in root module', error);
      }
    },

    //Handler to invalidate the token, if the error is authentication related and to clear caches when the error is caused by being blocked
    handleAPIError (context, error) {
      let clearNonPrivateCaches = false;
      //Try to search if the message contains an indicator of being blocked to clear the users non-private caches
      try {
        if (error.response && error.response.data && error.response.data.message) {
          if (Array.isArray(error.response.data.message)) {
            for (let message of error.response.data.message){
              if (message.messages && Array.isArray(message.messages)) {
                for (let messageObject of message.messages){
                  if (messageObject.message && messageObject.message.includes(BLOCKED_SEARCH_STRING)) clearNonPrivateCaches = true;
                }
              } else {
                if (message.includes(BLOCKED_SEARCH_STRING)) clearNonPrivateCaches = true;
              }
            }
          } else {
            if (error.response.data.message.includes(BLOCKED_SEARCH_STRING)) clearNonPrivateCaches = true;
          }
        } else if (error.message) {
          if (error.message.includes(BLOCKED_SEARCH_STRING)) clearNonPrivateCaches = true;
        }
      } catch (error) {
        console.error('Could not parse error', error);
      }

      if(error && error.response && error.response.status && AUTH_ERRORS.includes(error.response.status)){
        context.dispatch('auth/logout').catch(() => {}); //Calls clearAllPersonalData
        if (clearNonPrivateCaches) context.dispatch('auth/clearNonPrivateCaches').catch(() => {});
      }
    },

    retryUploads (context, manualRetry) {
      let user = context.rootGetters['auth/getUser'];
      return api.retryUploads(_.get(user, 'id'), manualRetry);
    },

    removeUpload (context, key) {
      let user = context.rootGetters['auth/getUser'];
      return api.removeUpload(key, _.get(user, 'id'));
    },

    completeNotification (context, notificationIndex) {
      let notifications = context.getters['getNotifications'];

      //If it is a valid notification try to execute its action
      if (Array.isArray(notifications) && notificationIndex < notifications.length) {
        let currentNotification = notifications[notificationIndex];
        
        return new Promise((resolve, reject) => {
          if (currentNotification.action != null) {
            switch (currentNotification.action) {
              case 'sw_update':
                //Send SKIP_WAITING to new service workers that are currently waiting
                return sendMessageToWaitingServiceWorkers({type: 'SKIP_WAITING'});
                //Never resolve to wait indefinitely for restart!
              case 'navigate':
                if (currentNotification.payload != null) console.log('Navigate to', currentNotification.payload); //TODO Implement
                return resolve();
            
              default:
                //Resolve even without valid action to catch older versions!
                return resolve('No valid action');
            }
          }

          //Resolve even without valid action to catch older versions!
          reject('Action did not complete!');
        })
        .then(() => context.commit('removeNotification', notificationIndex)) //Only remove notification if interaction was successful!
        .catch((error) => {
          console.error('Error completing notificaiton', error);
        })
      }
    },

    fetchMediaWithAuthentication (context, mediaURL) {
      //TODO First try to load from local file storage. If it exists, give back the file URL to let the system handle load. Handle everywhere else differently as it is not an Object URL! Can't revoke it!
      if (mediaURL == null) return undefined;

      //Returns the URL instance, if the origin matches
      let urlInstance = checkForSameOrigin(mediaURL, apiBaseURL, undefined, true);
      if (urlInstance != null) {
        let params = {};

        for (const [key, value] of urlInstance.searchParams.entries()) {
          params[key] = value;
        }

        //Get relative to the API as blob
        return api.get(urlInstance.pathname, {
          params, //Preserve params for special access tokens
          responseType: 'blob'
        }).then(response => {
          return response.data;
        });
      }
      return undefined; 
    }
  }
})

//Check every version of every module
const moduleKeys = Object.keys(modules);
try {
  store.commit('checkVersion', moduleKeys);
} catch (error) {
  console.error('Could not check version of root module', error);
}
for (let module of moduleKeys){
  try {
    store.commit(`${module}/checkVersion`);
  } catch (error) {
    console.error(`Could not check version of module ${module}`, error);
  }
}

//Intercept every response to sanitize it
api.instance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  store.dispatch('setOnline');
  //TODO Sanitize all API returns
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  if(error && error.response && error.response.status){
    store.dispatch('handleAPIError', error);
    throw error;
  } else if (error && (!error.response || !error.response.status)) {
    //Network error, no response or status set
    store.dispatch('setOffline');
    throw Error('unreachable');
  } else {
    throw error;
  }
});

function checkOnlineStatus(){ //TODO Not really necessary once other functions regularly check for updates of new data, but maybe also good still keeping it with a higher interval
  //Just get the header of the api main page to indicate online status - least amount of data
  api.head('').catch(() => {}); //TODO Also working in the background for sync?
}

setInterval(checkOnlineStatus, UPDATE_INTERVAL_MINS * 60 * 1000); //TODO Increase the interval every time to a ceratin threshold when offline

//Capture console calls and save them - Intercept original methods with own versions
for (let method of CONSOLE_INTERCEPT_METHODS) {
  let originalName = '_std_' + method; //Save original function
  console[originalName] = console[method].bind(console);
  
  console[method] = function(){
    let logArray = Array.from(arguments);
    //When the rolling storage has hit its limit, remove the oldest one
    logStorage.length()
      .then((logCount) => {
        if (logCount >= MAX_LOG_COUNT) {
          return logStorage.keys();
        } else {
          return null;
        }
      })
      .then((keys) => {
        if (keys != null && keys.length > 0) {
          let removePromiseChain = Promise.resolve();
          for (let key of keys.slice(0, (keys.length - MAX_LOG_COUNT) + 1)) {
            removePromiseChain = removePromiseChain.then(() => logStorage.remove(key));
          }
          return removePromiseChain;
        } else {
          return null;
        }
      })
      .catch(() => null) //Continue on error
      .then(() => {
        //Add the current log to the storage, after all is done
        return logStorage.set(Date.now().toString(), {
          type: method,
          log: logArray.flatMap((logValue) => { //Convert some unserializable objects
            if (logValue === null) {
              return 'null';
            } else if (logValue === undefined) {
              return 'undefined';
            } else if (typeof logValue === 'object' && !Array.isArray(logValue) && !(logValue instanceof Error)) {
              //Return media load errors separately
              if (logValue.type == 'error' && (logValue.target instanceof HTMLImageElement || logValue.target instanceof HTMLMediaElement)) {
                return _.compact([
                  'Failed to load media',
                  logValue.target.outerHTML,
                  //Also add any non-null attributes that might exist and contain more information
                  logValue.message,
                  logValue.stack,
                  logValue.reason
                ]);
              }

              let logValueObject = {};
              for (let key in logValue){
                if (logValue[key] === null) {
                  logValueObject[key] = 'null';
                } else if (logValue[key] === undefined) {
                  logValueObject[key] = 'undefined';
                } else {
                  logValueObject[key] = (logValue[key].toString) ? logValue[key].toString() : logValue[key];
                }
              }
              return logValueObject;
            } else if (typeof logValue === 'object' && (logValue instanceof Error)) {
              return _.compact([ 
                logValue.message,
                (logValue.stack != null) ? logValue.stack : undefined
              ]);
            }
            return logValue;
          })
        });
      })
      .catch((error) => {
        console['_std_error'].apply(console, ['Could not save log properly, falling back to string.', error]);
        //If it fails, try to add a string representation
        return logStorage.set(Date.now().toString(), {
          type: method,
          log: logArray.map((logValue) => logValue.toString())
        });
      })
      .catch((error) => {
        //If still an exception, add this info to the store
        return logStorage.set(Date.now().toString(), {
          type: method,
          log: ['Error when saving this log entry', error.toString()]
        });
      })
      .catch((error) => {
        //If all else fails, there is an issue with store, show info in console
        console['_std_error'].apply(console, ['Logs cannot be saved!', error]);
      });
      
    //Call the original function to still show it in the console
    console[originalName].apply(console, arguments);
  }
}

function handleUncaughtError(event) {
  console.error('Unhandled error', (event != null && event.reason) ? event.reason : event);
  return false; //Continue to error handler
}

//Handle uncaught errors and promise rejections
window.addEventListener('error', handleUncaughtError);
window.addEventListener('unhandledrejection', handleUncaughtError);

export default store