import { api } from '@/utils/api'

const MODULE_VERSION = 1; //UPDATE VERSION WITH EVERY BREAKING CHANGE OR ATTRIBUTE REMOVAL

import { checkmark, calendar, play, close, documents, sparkles } from 'ionicons/icons';

const STAFF_ROLES = ['administrator', 'collaborator'];

const getDefaultState = () => {
  return {
    version: undefined,
    view: 'all'
  }
};

export default {
  namespaced: true,

  state: getDefaultState,
  getters: {
    getStatusDesign (){
      return function(status) {
        switch (status) {
          case 'planned':
            return {
              color: 'secondary',
              icon: calendar
            };
          case 'started':
            return {
              color: 'primary',
              icon: play
            };
          case 'completed':
            return {
              color: 'success',
              icon: checkmark
            };
          case 'declined':
            return {
              color: 'danger',
              icon: close
            };
          case 'duplicate':
            return {
              color: 'warning',
              icon: documents
            };
          case 'open':
            return {
              color: 'tertiary',
              icon: sparkles
            };
        
          default:
            return {
              color: 'tertiary'
            };
        }
      }
    },
    viewOptions () {
      return ['all', 'trending', 'recent', 'most-wanted', 'most-discussed', 'my-votes', 'planned', 'started', 'completed', 'declined'];
    },
    getView (state) {
      return state.view;
    },
    getUserDesign (){
      return function(user) {
        let userDesign = {
          isStaff: (user != null && user.role != null && STAFF_ROLES.includes(user.role)),
          username: 'other'
        }

        if (user.me) { //Always use me, if it is me
          userDesign.username = 'me';
        } else if (userDesign.isStaff) { //Otherwise return staff if it is staff
          userDesign.username = 'staff';
        }

        return userDesign;
      }
    }
  },
  mutations: {
    checkVersion (state) { //Checks the version of this module and sets the state to default, if version is different
      //Used to reset state on breaking changes and to remove attributes that are no longer needed
      if (state.version !== MODULE_VERSION) {
        let defaultState = getDefaultState();
        Object.assign(state, defaultState); //Use setters to not impact any reactive functionality
        //Search for unused attributes and set them undefined, to remove them from the persisted state
        for (let key of Object.keys(state)){
          if (!(key in defaultState)){
            state[key] = undefined;
          }
        }
        state.version = MODULE_VERSION;
      }
    },
    setView (state, newView) {
      state.view = newView;
    },
    clearPersonalData () {}
  },
  actions: {
    fetchPosts (context, {query, view, limit, tags}) {
      return new Promise((resolve, reject) => {
        api
        .get('/general-feedback', { params: {
          query,
          view,
          limit: limit || 1000,
          tags
        }})
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    fetchPost (context, id) {
      return new Promise((resolve, reject) => {
        api
        .get(`/general-feedback/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    createPost (context, { title, description }) {
      return new Promise((resolve, reject) => {
        api
        .post('/general-feedback', {
          title,
          description
        })
        .then(response => response.completionPromise)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    votePost (context, id) {
      return new Promise((resolve, reject) => {
        api
        .post(`/general-feedback/${id}/votes`)
        .then(response => response.completionPromise)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    unVotePost (context, id) {
      return new Promise((resolve, reject) => {
        api
        .delete(`/general-feedback/${id}/votes`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    fetchComments (context, id) {
      return new Promise((resolve, reject) => {
        api
        .get(`/general-feedback/${id}/comments`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    addComment (context, { id, content }) {
      return new Promise((resolve, reject) => {
        api
        .post(`/general-feedback/${id}/comments`, {
          content
        })
        .then(response => response.completionPromise)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
      });
    }
  }
}