<template>
  <ion-toolbar>
    <ion-buttons class="nav-buttons" slot="start">
      <ExtendableChip
        v-if="isSubpage"
        class="back-button"
        :color="confirmDiscard ? 'danger' : undefined"
        :title="(confirmDiscard ? (discardQuestion || i18n.$t('default_interaction.discard_question')) : undefined)"
        :extendOnClick="confirmDiscard"
        :forwardClick="!confirmDiscard"
        forwardExtendedClick
        transparentWhenClosed
        button
        textIcon
        v-model:extended="showDiscardConfirmation"
        :collapseTimeout="5000"
        :brightness="0.8"
        >
        <template v-slot:permanent>
          <ion-back-button class="inner-button" default-href="/health"></ion-back-button>
        </template>
        <template v-slot:default>
          <ion-label class="discard-label">{{ (confirmDiscard ? (discardQuestion || i18n.$t('default_interaction.discard_question')) : undefined) }}</ion-label>
        </template>
      </ExtendableChip>
      
      <ion-button class="logo-button" v-else-if="!isSimple" href="/">
        <AnimatedLogo name="cycle-soft" class="logo" duration="1000" alt="App-Logo"></AnimatedLogo>
      </ion-button>
    </ion-buttons>
    <div id="title">
      <ion-label>
        <ion-icon v-if="!isSimple && isOffline" id="offline-icon" :icon="cloudOfflineOutline"></ion-icon>
        <span v-if="titleIcon" class="title-icon">
          <ion-icon :icon="titleIcon"></ion-icon>
        </span>
        {{ title }}
      </ion-label>
    </div>
    <ion-buttons v-if="!isSimple" slot="end">
      <CodeScannerButton class="code-scanner-button" @codeScanned="handleScannedCode"></CodeScannerButton>
      <LanguageMenu></LanguageMenu>
      <ExtendableChip
        class="menu-button-notification"
        :color="(notificationCount >= 1) ? 'secondary' : undefined"
        extendToLeft
        :transparentWhenClosed="notificationCount < 1"
        forwardClick
        forwardExtendedClick
        textIcon
        button
        v-model:extended="showNotifications"
        :closeOnOutsideClick="false"
        :collapseTimeout="5000"
        :brightness="0.8"
        >
        <template v-slot:permanent>
          <ion-menu-button class="inner-button menu" v-if="!isMenuDisabled" menu="settings"></ion-menu-button>
          <!-- If the menu is disabled, show some settings in the toolbar-->
          <ion-button class="inner-button" v-else @click="darkModeActive = !darkModeActive">
            <ion-icon slot="icon-only" :icon="darkModeActive ? moon : moonOutline"></ion-icon>
          </ion-button>
        </template>
        <template v-slot:default>
          <div :class="['animation-container', ((showNotifications) ? 'wiggle' : undefined)]">
            <ion-icon :icon="notificationsOutline"></ion-icon>
          </div>
          <ion-label>{{ notificationCount }}</ion-label>
        </template>
      </ExtendableChip>
    </ion-buttons>

  </ion-toolbar>
</template>

<script>
import { IonToolbar, IonLabel, IonButtons, IonBackButton, IonMenuButton, IonButton, IonIcon } from '@ionic/vue';
import { defineComponent, computed, watch, ref } from 'vue';
import { useStore } from 'vuex';

import { moon, moonOutline, cloudOfflineOutline, notificationsOutline } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";

import _ from 'lodash';

import CodeScannerButton from '@/components/CodeScannerButton.vue';

import LanguageMenu from '@/components/LanguageMenu.vue';

import ExtendableChip from '@/components/ExtendableChip.vue';

import AnimatedLogo from '@/components/AnimatedLogo.vue';

import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'MainToolbar',
  components: { IonToolbar, IonLabel, IonButtons, IonBackButton, IonMenuButton, IonButton, IonIcon, CodeScannerButton, LanguageMenu, ExtendableChip, AnimatedLogo },
  props: {
    isSubpage: Boolean,
    isSimple: Boolean,
    isMenuDisabled: Boolean, //Currently not used
    title: String,
    titleIcon: String,
    confirmDiscard: Boolean,
    discardQuestion: String
  },
  setup(){
    const store = useStore();

    const router = useRouter();

    const i18n = useI18n();

    const openNotifications = computed(() => {
      return store.getters['getNotifications'];
    });

    const notificationCount = computed(() => {
      return openNotifications.value.length;
    });

    const showDiscardConfirmation = ref(false);

    const showNotifications = ref(false);

    //Close the other one if one opens
    watch(showDiscardConfirmation, (newStatus) => {
      if (newStatus === true) showNotifications.value = false;
    });
    watch(showNotifications, (newStatus) => {
      if (newStatus === true) showDiscardConfirmation.value = false;
    });

    watch(openNotifications, (newNotifications, oldNotifications) => {
      if (!_.isEqual(oldNotifications, newNotifications) && newNotifications.length >= 1) {
        showNotifications.value = true;
      }
    }, { immediate: true })

    //Used for simple button, current state is visualized. If pressed, preference will be set
    const darkModeActive = computed({
      get: () => store.getters.isDarkModeActive,
      set: newValue => {
        store.dispatch('setDarkModePreference', newValue);
      }
    });

    const isOffline = computed(() => {
      return !(store.getters.isOnline);
    });

    const handleScannedCode = function(code) {
      if (code != null && code.isInternalCode) {
        if (code.processedValue != null && code.processedValue.shortType != null && (code.processedValue.id != null || code.processedValue.uuid != null)) {
          router.push({name: 'link', params: { type: code.processedValue.shortType, id: (code.processedValue.id || code.processedValue.uuid) }});
        } //TODO Show error when not recognized?
      } else {
        console.log(code);
        //TODO If not an internal code, or if not assigned yet, ask what to do in a modal. Otherwise route there! Could also get that from the route as additional params if coming from outside.
      }
    }

    return { i18n, notificationCount, showDiscardConfirmation, showNotifications, darkModeActive, isOffline, handleScannedCode, moon, moonOutline, cloudOfflineOutline, notificationsOutline };
  }
});
</script>

<style scoped>
.nav-buttons {
  height: 100%;
  position: relative;
}
.logo-button {
  flex-grow: 1;
  height: 100%;
  --padding-start: 5px;
  --padding-end: 5px;
}
.logo {
  flex-grow: 1;
  margin: 5px;
  font-size: 2.5rem;
  color: var(--ion-color-primary);
}

.title-icon {
  height: 100%;
  vertical-align: middle;
  display: inline;
  padding-left: 5px;
  padding-right: 5px;
}

#title {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.ios #title ion-label {
  font-size: clamp(.8em, 4vw, 17px);
  font-weight: 600;
}

#title ion-label {
  width: 100%;
  padding: 0px 10px;
  font-size: clamp(.8em, 4vw, 20px);
  font-weight: 500;
  letter-spacing: 0.0125em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#offline-icon {
  transform: translateY(16%);
  margin-right: 3px;
  font-size: 1em;
  color: var(--ion-color-tertiary, violet);
}

.back-button, .menu-button-notification {
  margin: 0px;
  padding: 0px;
}

.back-button .inner-button:not(.ios) {
  color: var(--ion-text-color, #000);
}

.back-button.extended .inner-button:not(.ios) {
  color: var(--ion-color-contrast, #fff);
} 

.menu-button-notification .inner-button {
  --padding-start: 0px;
  --padding-end: 0px;
}

.menu-button-notification .inner-button.menu {
  width: 30px;
  height: 30px;
}

.inner-button {
  pointer-events: none;
}

@keyframes bell {
  0% { transform: rotate(0); }
  15% { transform: rotate(15deg); }
  30% { transform: rotate(-15deg); }
  45% { transform: rotate(10deg); }
  60% { transform: rotate(-10deg); }
  75% { transform: rotate(5deg); }
  85% { transform: rotate(-5deg); }
  92% { transform: rotate(2deg); }
  100% { transform: rotate(0); }
}

.animation-container {
  vertical-align: bottom;
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 0.55em;
}

.animation-container.wiggle {
  animation: bell 1s cubic-bezier(.36,.07,.19,.97) 2 both;
}

.discard-label {
  white-space: nowrap;
  margin-right: 5px;
}

.ios .discard-label {
  margin-left: 5px;
}

.code-scanner-button {
  --icon-font-size: 1.75em;
  --icon-padding: 0.5em;
  --button-padding-inline: 5px;
  --color: var(--ion-color-primary-text);
}
</style>
