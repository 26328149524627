import { faTag, faUser, faHouse, faPhone, faMarsAndVenus, faHashtag, faDna, faCakeCandles, faAddressCard, faEllipsis, faImage, faPalette, faUserDoctor, faEnvelope, faWarehouse, faHourglassEnd, faShieldHeart } from '@fortawesome/free-solid-svg-icons';
import { computed } from 'vue';

import { useStore } from 'vuex';
import { useI18n } from "@/utils/i18n";
import { useDayjs, ageYearFormat, ageDateFormat } from '@/utils/dayjs';

import { horseGenders, horseCoatColors, horseRaces } from '@/config/horseAttributes';

import { openEditModal, default as editModalComponent } from '@/components/EditModal.vue';

import _ from 'lodash';

export function useEditAnimalModal() {
  const i18n = useI18n();

  const { calculateAgeFromBirthdate, calculateBirthyearFromAge, parseAndFormat, parseString } = useDayjs();

  const store = useStore();

  const transformAttributes = function(attributesByCategory, locale) {
    return _.flatMap(attributesByCategory, (attributeCategory) => {
      //Only add category to option if not undefined
      let category = _.get(attributeCategory, ['category', locale], undefined);

      return _.map(attributeCategory.options, (attribute) => {
        return { 
          value: attribute.value,
          display: _.get(attribute, [locale], attribute.value), //Try to get translation or take value as fallback
          category: category || undefined
        }
      })
    });
  }

  const genderSelection = computed(() => {
    let locale = i18n.locale.value;
    //Adaptable for other animals
    let genders = horseGenders;
    
    return transformAttributes(genders, locale);
  });

  const colorSelection = computed(() => {
    let locale = i18n.locale.value;
    //Adaptable for other animals
    let colors = horseCoatColors;
    
    return transformAttributes(colors, locale);
  });

  const raceSelection = computed(() => {
    let locale = i18n.locale.value;
    //Adaptable for other animals
    let races = horseRaces;
    
    return transformAttributes(races, locale);
  });

  const translations = computed(() => {
    let locale = i18n.locale.value;
    //Adaptable for other animals
    let originalValues = {
      'gender': horseGenders,
      'color': horseCoatColors,
      'race': horseRaces
    }

    return _.mapValues(originalValues, (attributesByCategory) => {
      let translationByValues = {};
      _.forEach(attributesByCategory, (attributeCategory) => {
        _.forEach(attributeCategory.options, (attribute) => {
          if (attribute.value != null) {
            translationByValues[attribute.value] = _.get(attribute, [locale], attribute.value); //Try to get translation or take value as fallback
          }
        });
      });

      return translationByValues;
    });
  });

  const translateAttribute = computed(() => {
    let translationMapping = translations.value;

    return function(value, attribute) {
      return _.get(translationMapping, [attribute, value]); //Try to get the translation or return undefined if not found
    }
  });

  //Defines the order of the available fields and additional metadata
  const formTemplate = computed(() => {
    let availableGenders = genderSelection.value;
    let availableColors = colorSelection.value;
    let availableRaces = raceSelection.value;

    return [
      {
        descriptor: 'general',
        isRootAttribute: true,
        fields: [
          { descriptor: 'name', required: true, icon: faTag, iconIsFontAwesome: true },
          { descriptor: 'unique_identifier', icon: faHashtag, iconIsFontAwesome: true },
          { descriptor: 'image', type: 'image', icon: faImage, iconIsFontAwesome: true }, //TODO API should keep files depending on the hashes that are sent, and all new ones, delete ones that are not included!
          { descriptor: 'postcode_stable', icon: faWarehouse, iconIsFontAwesome: true },
        ],
      },
      {
        descriptor: 'additional',
        icon: faEllipsis,
        iconIsFontAwesome: true,
        allowCustomFields: true,
        fields: [
          { descriptor: 'gender', icon: faMarsAndVenus, iconIsFontAwesome: true, availableValues: availableGenders, allowCustomValues: true },
          { descriptor: 'race', icon: faDna, iconIsFontAwesome: true, availableValues: availableRaces, allowCustomValues: true, allowMultipleValues: true },
          { options: [ //Allow input of one or the other but not both
              { descriptor: 'birthday', type: 'date' },
              { descriptor: 'age', type: 'number', style: 'flex-shrink: 4' }
            ], icon: faCakeCandles, iconIsFontAwesome: true },
          { descriptor: 'slaughter', type: 'bool', icon: faHourglassEnd, iconIsFontAwesome: true },
          { descriptor: 'insured', type: 'bool', icon: faShieldHeart, iconIsFontAwesome: true },
          { descriptor: 'microchip_number', icon: faHashtag, iconIsFontAwesome: true },
          { descriptor: 'color', availableValues: availableColors, icon: faPalette, iconIsFontAwesome: true, allowCustomValues: true }
        ]
      },
      {
        descriptor: 'personal_data',
        icon: faAddressCard,
        iconIsFontAwesome: true,
        allowCustomFields: true,
        fields: [
          { descriptor: 'name', icon: faUser, iconIsFontAwesome: true },
          { descriptor: 'address', type: 'address', icon: faHouse, iconIsFontAwesome: true },
          { descriptor: 'address_stable', type: 'address', icon: faWarehouse, iconIsFontAwesome: true },
          { descriptor: 'phone', type: 'tel', icon: faPhone, iconIsFontAwesome: true },
          { descriptor: 'email', type: 'email', icon: faEnvelope, iconIsFontAwesome: true },
        ] //TODO Mehrere Telefonnummern, auch bei hinzugefügten Feldern Phone erlauben

        /*TODO Status-Felder (weit oben) {

          Schnelle Notizen
          Kommunikationsprotokoll - schneller Zugriff

          OPs als Liste (Datum und OP-Typ)//TODO Wenn OP Protokoll ausgefüllt, wird automatisch auch der Status generiert.
        }*/
      },
      {
        descriptor: 'personal_data_vet',
        icon: faUserDoctor,
        iconIsFontAwesome: true,
        allowCustomFields: true,
        fields: [
          { descriptor: 'veterinarian', icon: faUserDoctor, iconIsFontAwesome: true },
          { descriptor: 'veterinarian_phone', type: 'tel', icon: faPhone, iconIsFontAwesome: true },
          { descriptor: 'veterinarian_email', type: 'email', icon: faEnvelope, iconIsFontAwesome: true }
        ]
      }
    ]
  });

  const open = async function(animalId, presetName, viewMode = false) {
    let existingAnimal = null;
    if (animalId != null) {
      existingAnimal = _.cloneDeep(store.getters['horses/getPersonalInfoById'](animalId)); //Clone to not modify the original proxy object!
    }

    if (existingAnimal != null) {
      //Convert birthday to ISO string
      const birthdayPath = ['additional', 'birthday'];
      let birthday = _.get(existingAnimal, birthdayPath);
      if (birthday != null) {
        _.set(existingAnimal, birthdayPath, parseString.value(birthday, ageDateFormat).toISOString());
      }

      //Convert birthyear to age
      const yearPath = ['additional', 'birthyear'];
      let birthyear = _.get(existingAnimal, yearPath);
      if (birthyear != null) {
        _.unset(existingAnimal, yearPath);
        _.set(existingAnimal, ['additional', 'age'], calculateAgeFromBirthdate.value(birthyear, ageYearFormat));
      }
    }

    let presetValues = null;
    if (presetName != null) {
      presetValues = {
        name: presetName
      }
    }

    return openEditModal(editModalComponent, {
      formTemplate: formTemplate.value,
      automaticLocalizationPrefix: 'animal.attributes',
      viewMode,
      createTitle: i18n.$t('animal.edit.title.create'),
      editTitle: i18n.$t('animal.edit.title.edit'),
      viewTitle: i18n.$t('animal.edit.title.view'),
      deleteText: i18n.$t('animal.edit.delete.title'),
      deleteQuestion: i18n.$t('animal.edit.delete.question'),
      deleteConfirmation: i18n.$t('animal.edit.delete.confirmation'),
      deleteWaitTimeSeconds: 0,
      presetValues,
      existingValues: existingAnimal,
      keepEmptyRootAttributes: true,
      enableDelete: true
    }).then(async ({action, data, files = {}}) => {
      if (action !== 'delete' && (data == null || Object.keys(data).length < 1) && (files == null || Object.keys(files).length < 1)) return;
      let animalObject = (data == null) ? {} : {...data}; //Create a shallow copy to remove properties

      //Convert birthday into simple date format
      const birthdayPath = ['additional', 'birthday'];
      let birthday = _.get(animalObject, birthdayPath);
      if (birthday != null) {
        _.set(animalObject, birthdayPath, parseAndFormat.value(birthday, ageDateFormat));
      }

      //Convert age to birthyear
      const agePath = ['additional', 'age'];
      let age = _.get(animalObject, agePath);
      if (age != null) {
        _.unset(animalObject, agePath);
        _.set(animalObject, ['additional', 'birthyear'], calculateBirthyearFromAge.value(age));
      }

      if (action === 'update' && animalId != null) {
        return store.dispatch('horses/updateHorse', {id: animalId, animalInfo: animalObject, files}).then((uploadStatus) => uploadStatus.key);
      } else if (action === 'create') {
        return store.dispatch('horses/createNewHorse', {animalInfo: animalObject, files}).then((uploadStatus) => uploadStatus.key);
      } else {
        throw 'No valid action';
      }
    });
  }

  return { open, translations, translateAttribute };
}